<template>
  <div>
    <b-container fluid class="bg-login full-height">
      <b-row class="full-height">
        <b-col cols="12" md="6" class="left-side" :style="'background-image: url('+backgroundImage+')'"> </b-col>
        <b-col cols="12" md="6" class="right-side">
          <div class="inner-wrapper" data-anima="right" v-if="!loading">
            <span class="logo mb-5">
              <img v-if="!isGreenn" src="@/assets/img/G-digital-text.png" />
              <img
                v-else
                src="@/assets/greenn_sales/GREENN_SALES_.png"
                style="width: 198px; position: relative; left: -14px"
              />            </span>
            <h1 class="reenv-verify">
              Enviamos um link de verificação no e-mail no momento do seu
              cadastro, caso não tenha recebido, você poderá clicar no botão a
              baixo
            </h1>
            <BaseButton
              variant="primary"
              ref="button"
              :disabled="loading"
              type="submit"
              style="margin-left: 15px"
              :class="{ mg: isMobile }"
              @click="onSubmit"
            >
              Enviar novo link de verificação
            </BaseButton>

            <a
              @click="logout"
              to="/dynamicRoute/login"
              class="acesssar-outra-conta"
            >
              Acessar outra conta
            </a>
          </div>

          <div
            v-if="loading"
            class="py-4 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
import BaseView from "@/template/BaseView.vue";
//
import UsuariosService from "@/services/resources/UsuariosService";
const serviceVerify = UsuariosService.build();
//
export default {
  components: {
    BaseView,
  },
  data() {
    return {
      backgroundImage:'',
      intervalId: null,
      loading: false,
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function (err) {});
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    onSubmit() {
      var data = {
        id: "/verify/send-verify",
        email: this.$store.getters.user.user.email,
      };
      serviceVerify
        .createId(data)
        .then((resp) => {
          if(resp.message == 'VERIFIED_EMAIL'){
            return this.$store.dispatch("redirectUserToHome2");
          }
          this.$grToast.toast("Enviamos a verificação no seu e-mail", {
            title: "Verificar e-mail",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          this.$grToast.toast("Erro ao e enviar verificação de e-mail", {
            title: "Verificar e-mail",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {});
      // console.log("Reenviar e-mail de verificação");
    },
    allValidEmail(token, time) {
      if (token) {
        var check = {
          id: `/verify/check-email/${token}`,
        };
        serviceVerify
          .createId(check)
          .then((resp) => {
            // console.log("check response da api", resp);
            if (resp.success === true) {
              clearInterval(this.intervalId);
              this.$store.dispatch("verifyEmail");
            }
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {});
      }
      // var email = {
      //   id: "/verify/email-verified",
      // };
      // serviceVerify
      //   .createId(email)
      //   .then((resp) => {
      //     // console.log(" email response da api", resp);
      //   })
      //   .catch((error) => {
      //     // console.log(error);
      //   })
      //   .finally(() => {});
    },
  },
  mounted() {
    if(this.isGreenn){
      this.backgroundImage = window.location.origin + '/banner2.webp'
    }else{
      this.backgroundImage =  window.location.origin + '/banner1.png'
    }
    // console.log("token", this.$route.params.token);
    this.onSubmit();
    this.allValidEmail(this.$route.params.token, "not_interval");
    this.intervalId = setInterval(() => {
      this.allValidEmail(this.$route.params.token, "interval");
    }, 5000);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.reenv-verify {
  color: #81858e;
  font-size: 18px;
}
.mg {
  margin-right: 15px !important;
  margin-left: 0px !important;
}

.end {
  justify-content: flex-end !important;
}

.text-green {
  color: var(--greenn);
  font-weight: bold;
}

.bg-login {
  background: var(--greenn);
  overflow: hidden;
}

.form-group {
  position: relative;
}

.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-image: url("~@/assets/banners/login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px 0px;
  height: 100%;

  .inner-wrapper {
    display: grid;
    gap: 15px;
    margin-top: 90px;
    padding: 6% 18%;
    // width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}

.acesssar-outra-conta {
  cursor: pointer;
  color: #81858e;
  font-size: 14px;
}

.links-help {
  font-size: 12px;
  color: #81858e;
  margin-top: 20px;
}

.btn-acoes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

@media screen and (max-width: 768px) {
  .right-side .inner-wrapper {
    padding: 6% 11%;
  }

  .grecaptcha-badge {
    right: -194px !important;
  }

  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }

  .links-help {
    margin: 0;
  }
}

.full-height {
  height: 100vh;
}
</style>
